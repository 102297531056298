
import { defineComponent, getCurrentInstance, SetupContext, PropType, ref, watch, onMounted, reactive, computed } from 'vue';
import ActionSheet from 'vant/lib/action-sheet';
import Loading from '../loading-dark/index.vue';
import { roundingNum } from '@/common/utils';
import { UserComment, FeedApi } from '@/api/feed';
import { PwaBridge } from '~/common/bridge';

function formatDateDiffToNow(millisecondsTimestamp: number) {
    const inputDate = new Date(millisecondsTimestamp) as any; // 使用毫秒级别的时间戳
    const now = new Date() as any;
    const diffMilliseconds = now - inputDate;
    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    if (diffSeconds < 60) {
        return 'Just now';
    }
    if (diffMinutes < 60) {
        return `${diffMinutes}m ago`;
    }
    if (diffHours < 24) {
        return `${diffHours}h ago`;
    }
    if (diffDays < 7) {
        if (now.getFullYear() === inputDate.getFullYear()) {
            return `${diffDays}d ago`;
        }
    } else if (diffDays < 365) {
        return inputDate.toLocaleDateString(undefined, { month: '2-digit', day: '2-digit' }).replaceAll('/', '-');
    } else {
        return inputDate
            .toLocaleDateString({
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
            .replaceAll('/', '-');
    }
}

const CommentList = defineComponent({
    components: {
        VanActionSheet: ActionSheet,
        Loading,
    },
    props: {
        visibile: Boolean,
        photoId: {
            type: String,
            default: () => '',
        },
    },
    setup(props, ctx: SetupContext<Array<'onClose'>>) {
        const { proxy } = getCurrentInstance() || {};

        const isLoading = ref(false);
        const commentList = ref<UserComment[]>([]);
        const currentFeedPhotoId = ref<string>('');

        const fetchCommentList = async (photoId?: string) => {
            if (!photoId) {
                commentList.value = [];
                return;
            }
            try {
                isLoading.value = true;
                const res = await FeedApi.getUserCommentList({
                    photo_id: photoId,
                });
                commentList.value = res?.comments || [];
                isLoading.value = false;
            } catch {
                commentList.value = [];
                isLoading.value = false;
            }
        };

        const handleClose = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('onClose');
        };

        const handleViewUser = async (params: Record<string, any>) => {
            const url = `${window?.location?.origin}/@${params?.kwaiid}${window?.location?.search}`;
            if (proxy?.$isAppEnvironment) {
                PwaBridge.openWebview({
                    url,
                });
                return;
            }

            window.open(url, '_blank');
        };

        onMounted(() => {
            fetchCommentList(props.photoId || '');
            currentFeedPhotoId.value = props.photoId;
        });

        watch(
            () => ({
                visible: props.visibile,
                photoId: props.photoId,
            }),
            newVal => {
                if (newVal.visible && currentFeedPhotoId.value !== newVal.photoId) {
                    currentFeedPhotoId.value = newVal.photoId;
                    fetchCommentList(newVal.photoId);
                }
            }
        );

        return {
            handleClose,
            roundingNum,
            handleViewUser,
            formatDateDiffToNow,

            isLoading,
            commentList,
        };
    },
});

export default CommentList;
